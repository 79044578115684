import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import '../src/fonts&Styles/App.css';
import GlobalStyle from "./fonts&Styles/globalStyle";
import App from "./App";
import { LoginProvider } from "./context/login&Register_context";
import { LeadServerProvider } from "./context/lead_server_context";
import { AdminstrationProvider } from "./context/adminstration_context";
import { ClientAreaProvider } from "./context/client_area_context";
import { AllSheetsProvider } from "./context/all_sheets_context";


ReactDOM.render(
  <Fragment>
    <GlobalStyle />
    <LoginProvider>
      <LeadServerProvider>
        <AdminstrationProvider>
          <ClientAreaProvider>
            <AllSheetsProvider>
             <App />
            </AllSheetsProvider>
          </ClientAreaProvider>
        </AdminstrationProvider>
      </LeadServerProvider>
    </LoginProvider>
  </Fragment>,
  document.getElementById("root")
);
