import React, { useEffect } from "react";
import { useAllSheetsContext } from "../context/all_sheets_context";
import styled from "styled-components";
import { ThemeProviderToPages } from "../components/ThemeProvider";
import {AllSheetsColumn} from '../components/table/tableComponents/AllSheetsColumns'
import navData from "../context/mockdata/nav-contents-admin";
import { useLoginContext } from "../context/login&Register_context";



export const ClientSheet = () => {
    let {filterSheets: filterSheetData, fetchAllSheets, sheetsLoading, sheets, allSheets, filterLeads_loading} = useAllSheetsContext();
    let {user} = useLoginContext();

    useEffect(() => {
        fetchAllSheets(user)
    }, [user]);

    const filteredUpto50Sheet = [];

    // Sending only 49 filtered Lead to Leadserver UI
    filterSheetData.forEach((element, i) => {
      if (i < 49) {
        filteredUpto50Sheet.push(element);
      }
    });
  
    if (filteredUpto50Sheet.length > 0) {
      sheets = filteredUpto50Sheet;
    }

    return (
        <Wrapper>
        <ThemeProviderToPages
          TableData={sheets}
          allFilteredLeads={allSheets}
          
          Columns={AllSheetsColumn}
          Reset={fetchAllSheets}
          filterLoading={filterLeads_loading}
          navData={navData}
          loading={sheetsLoading}
          title={"Welcome To Client Sheets"}
        />
      </Wrapper>
    )
}

const Wrapper = styled.section``;