import axios from 'axios';
import React, { useContext, useReducer } from 'react'
import reducer from '../reducers/client_area_reducer'
import { getToken, hostAddress } from '../utils/helpers';
import { getDataViaPageResponce, getFilterDataResponce, globalFilterResponce } from './context_helpers/handleFactory';
import { useLoginContext } from './login&Register_context';

const initialState = {
    clientLeadsLoading: false,
    clientLeads: [],
    allFilteredLeads: 0,
    filterClient_loading: false,
    filterClientLeads: [],
    filterQuery: '',
}

const ClientAreaContext = React.createContext();


export const ClientAreaProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // const {user} = useLoginContext()
      // Model meaning indicate the filter table on backend
    const model = 'leads'
   
    const getingClientData = async (user) => {
        dispatch({type: 'getClientLeadsLoading'});

        try {
            const token = getToken();
            const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            };
            
            const responce = await axios.get(
            `${hostAddress}/leads/filter/?clientId=${user.userId}`,
            config
            );

            const leads = responce.data.data.data;
            const allFilteredLeads = responce.data.filteredDataLength;

            //Remake filter query
            const filterQuery = `?clientId=${user.userId}`

            dispatch({type: 'getClientLeadsSuccess', payload: {leads, allFilteredLeads, filterQuery}})


        } catch (error) {
            const errorMessage = JSON.parse(error.request.responseText)
            alert(errorMessage.message)
        }
    }

    const updateFilterData = async (filterQuery) => {
        try {
    
            dispatch({ type: "updateFilterDataLoading" });
      
          const {filteredData, allFilteredData} = await getFilterDataResponce(filterQuery, model);

          if(filteredData === '') {
            dispatch({type: 'updateFilterDataLoadingStop'})
            return ''
          }
          
          dispatch({
                  type: "getClientFilterDataSuccess",
                  payload: { filteredData, allFilteredData, filterQuery},
           });
      
    
        } catch (error) {
            const errorMessage = JSON.parse(error.request.response);
            alert(errorMessage.message);
        }
      
        }

 const getDataViaPage = async (page, filterQuery, limit, user) => {
            try {
                dispatch({ type: "updateFilterDataLoading" });
      
                const {filteredData, allFilteredData} = await getDataViaPageResponce(model, page, filterQuery, limit, user);
            
                      dispatch({
                    type: "getClientFilterDataSuccess",
                    payload: { filteredData, allFilteredData, filterQuery},
                  });
            } catch (error) {
                const errorMessage = JSON.parse(error.request.response);
                alert(errorMessage.message);
            }
    
            
        }

         // Global filter
  const globalFilter = async (filterQueryGlobal) => {

   
        dispatch({ type: "updateFilterDataLoading" });
    
        const responce = await globalFilterResponce(model, filterQueryGlobal);

        if(responce) {
            const {filteredData, allFilteredData} = responce;
        //Remake filter query for show to Your show filter
        const filterQuery = `globalfilter?${filterQueryGlobal}`
    
           dispatch({
          type: "getClientFilterDataSuccess",
          payload: { filteredData, allFilteredData, filterQuery},
        });
        } else {
            return ''
        }
    
  }

  // clean filter query for solve another one issue.
  const cleanQuery = () => {
    dispatch({type: "cleanQuery"});
  };
  

    return (
        <ClientAreaContext.Provider value={{...state, getingClientData, updateFilterData, getDataViaPage, globalFilter, cleanQuery}}>{children}</ClientAreaContext.Provider>
    )
}

// make sure use
export const useClientAreaContext = () => {
    return useContext(ClientAreaContext);
}