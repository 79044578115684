import axios from 'axios';
import React, { useReducer, useContext, useState } from 'react'

import reducer from '../reducers/all_sheets_reducer';
import { getToken, hostAddress } from '../utils/helpers';
import { getDataViaPageResponce, getFilterDataResponce } from './context_helpers/handleFactory';

const initialState = {
    sheetsLoading: false,
    sheets: [],
    allSheets: 0,
    filterSheets: [],
    filterLeads_loading: false,
    filterQuery: ''
}

const AllSheetsContext = React.createContext();


export const AllSheetsProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [user, setUser] = useState([]);

    // model for filter data
    let model = 'sheets/admin'

    const fetchAllSheets = async (user) => {   
        dispatch({ type: "getAllSheetsLoading" });
        try {
            const token = getToken();
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

            
            if(user === {}) return ''
         
            // set user for using into the others function
            setUser(user)

            let responce;
            let sheets;
            let allSheetsResults;
            if (user.role === 'admin' || user.role === 'moderator') {
              responce = await axios.get(`${hostAddress}/sheets`, config);
              sheets = responce.data.data.docs;
              allSheetsResults = responce.data.allDocs;
            } else if (user.role === 'client') {
              responce = await axios.get(
                `${hostAddress}/sheets/client/filter/?clientId=${user.userId}`,
                config
              );
              sheets = responce.data.data.data;
              allSheetsResults = responce.data.filteredDataLength;
            } else {
              return '';
            }

            dispatch({type: "getAllSheetsSuccess", payload: {sheets, allSheetsResults}})
           
        } catch (error) {
            const errorMessage = JSON.parse(error.request.response);
            alert(errorMessage.message);
           
        }
    }

      //Filter lead data
  const updateFilterData = async (filterQuery) => {

      dispatch({ type: "updateFilterDataLoading" });

      let response;
      let filteredData;
      let allFilteredData;

    if (user.role === 'admin' || user.role === 'moderator') {
      response = await getFilterDataResponce(filterQuery, model);
      filteredData = response.filteredData;
      allFilteredData = response.allFilteredData;
    } else if (user.role === 'client') {
      const clientModel = 'sheets/client';
      response = await getFilterDataResponce(filterQuery, clientModel);
      filteredData = response.filteredData;
      allFilteredData = response.allFilteredData;
    }

    // const {filteredData, allFilteredData} = ;
    
    dispatch({
            type: "getSheetFilterDataSuccess",
            payload: { filteredData, allFilteredData, filterQuery},
    });
    }

    // right now it's not implemented.
    const getDataViaPage = async (page, filterQuery, limit, user) => {
        model = 'sheets'
        try {
            dispatch({ type: "updateFilterDataLoading" });
            const {filteredData, allFilteredData} = await getDataViaPageResponce(model, page, filterQuery, limit, user);
        
                  dispatch({
                type: "getSheetFilterDataSuccess",
                payload: { filteredData, allFilteredData, filterQuery},
              });
        } catch (error) {
            const errorMessage = JSON.parse(error.request.response);
            alert(errorMessage.message);
        }
    }

    //global filer

    return (
        <AllSheetsContext.Provider value={{...state, fetchAllSheets, updateFilterData, getDataViaPage}}>{children}</AllSheetsContext.Provider>
    )
}

export const useAllSheetsContext = () => {
    return useContext(AllSheetsContext)
}