import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLoginContext } from "../context/login&Register_context";
import { Link, Redirect } from "react-router-dom";
import "../fonts&Styles/SignIn&SignUp.css";

const Login = () => {
  const [emailInput, setEmail] = useState("");
  const [passwordInput, setPassword] = useState("");

  let { user, isAuthenticated, checkingAuthentication, allowUser } =
    useLoginContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    checkingAuthentication(emailInput, passwordInput);
  };

  useEffect(() => {
    if (user.role) {
      allowUser(user);
    }
  }, [user]);

  if (!isAuthenticated) {
    return (
      <Wrapper className="account">
        {/* checking Form */}
        <div className="section-signin">
          <h1 className="section-signin_header">Sign In</h1>
          <p className="section-signin_para">
            Happy to see you here. Khalid IT
          </p>
          <form className="section-signin_form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="email"
              className="section-signin_form-input"
              placeholder="Email / User Id"
              onChange={e => setEmail(e.target.value)}
              required
            />
            <br />
            <input
              type="password"
              name="customer[password]"
              id="customerpassword"
              className="section-signin_form-input"
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
              required
            />
            <br />
            <Link
              className="section-signin_form-recovery"
              to="/forget-password"
            >
              Forgot your password?
            </Link>
            <br />
            <input
              type="submit"
              className="section-signin_form-submit"
              value="SIGN IN"
            />
            <p className="section-signin_form-signup">
              Don't have account? Please &nbsp;
              <Link to="/signup" className="section-signin_form-signup--link">
                Sign Up
              </Link>
            </p>
          </form>
        </div>
        {/* checking Form end*/}
      </Wrapper>
    );
  } else {
    return (
      <div>
        <Redirect to="/dashboard">Go</Redirect>
      </div>
    );
  }
};

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: white;
  height: 100vh;

  /* label {
    font-size: 2rem;
  } */
  /* input {
    font-size: 1.6rem;
    padding: 0.3rem;
  }
  .btn {
    text-align: center;
    margin-top: 1rem;
  }
  .log-in {
    cursor: pointer !important;
  } */
`;

export default Login;
