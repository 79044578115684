import React from "react";
import styled from "styled-components";

const UnderConstruction = () => {
  return (
    <Wrapper>
      <h1 className="heading">COMING SOON...</h1>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .heading {
    color: #7f95b0d9;
    padding: 15rem 17rem 0 28rem;
    text-align: center;
    font-size: 3rem;
  }
`;

export default UnderConstruction;
