import React from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import { BsDownload } from "react-icons/bs";
import { useLeadServerContext } from "./../context/lead_server_context";
import { useLocation } from "react-router-dom";

export default function ExportsData({ data, filterLoading, COLUMNS }) {
  const { filterLeads: filterleadData } =
    useLeadServerContext();

  let location = useLocation();

  
  if (filterLoading) {
    return (
      <Wrapper>
        <h1 className="Loading">Loading...</h1>
      </Wrapper>
    );
  }
  if (filterleadData.length > 0 && location.pathname.includes("lead-server")) {
    data = filterleadData;
  }

  // Generating dynamic label and key from COLUMNS for export data.
  if (data !== undefined) {
    const headersIndividual = COLUMNS.map((e) => {
      return [{ label: e.Header, key: e.accessor }];
    });
    let exportDataHeaders = [];
    for (let index = 0; index < headersIndividual.length; index++) {
      const element = headersIndividual[index];
      exportDataHeaders.push(element[0]);
    }

    const csvReport = {
      filename: "Report.xls",
      headers: exportDataHeaders,
      data: data,
    };
    return (
      <Wrapper>
        <CSVLink {...csvReport} className="btn_default margin-small">
          <span className="download_icon">
            <BsDownload />
          </span>
          <span className="export-text">Export</span>
        </CSVLink>
      </Wrapper>
    );
  } else {
    return ''
  }
}

const Wrapper = styled.section`

  .Loading {
    color: #000;
    font-size: 1.4rem;
    display: block;
    margin: 0.2rem 0.5rem 0rem 0rem;
    -webkit-animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      infinite both;
    animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
      both;
  }

  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
`;
