import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLeadServerContext } from '../context/lead_server_context';
import { useLoginContext } from '../context/login&Register_context';
import ReactPaginate from 'react-paginate';

//icons
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { useAdminstrationContext } from '../context/adminstration_context';
import { useClientAreaContext } from '../context/client_area_context';
import { useAllSheetsContext } from '../context/all_sheets_context';

export const Pagination = allFilteredLeads => {
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(50);
  const [limit, setLimit] = useState('');

  const {
    filterQuery: leadServerFilterQuery,
    filterLeads,
    getDataViaPage: getDataViaPageLeads,
  } = useLeadServerContext();
  const {
    getDataViaPage: getDataViaPageUsers,
    filterQuery: adminstrationFilterQuery,
  } = useAdminstrationContext();
  const {
    getDataViaPage: getDataViaPageClient,
    filterQuery: clientAreaFilterQuery,
  } = useClientAreaContext();
  const {
    getDataViaPage: getDataViaPageSheets,
    filterQuery: all_sheetsFilterQuery,
  } = useAllSheetsContext();

  const { user } = useLoginContext();

  const allPages = Math.ceil(allFilteredLeads.allFilteredLeads / itemsPerPage);
  const pages = [];

  const location = useLocation();

  // storing all page what we have into pages,
  for (let index = 0; index < allPages; index++) {
    // console.log(index);
    pages.push(index);
  }

  // Geting data without limit
  const handleGetingData = data => {
    console.log(data.selected);

    setcurrentPage(data.selected + 1);

    if (location.pathname.includes('sheets')) {
      location.pathname.includes('lead-server') &&
        getDataViaPageSheets(data.selected + 1, all_sheetsFilterQuery, limit);
    } else {
      location.pathname.includes('lead-server') &&
        getDataViaPageLeads(data.selected + 1, leadServerFilterQuery, limit);
      location.pathname.includes('adminstration') &&
        getDataViaPageUsers(data.selected + 1, adminstrationFilterQuery, limit);
      location.pathname.includes('client-area') &&
        getDataViaPageClient(
          data.selected + 1,
          clientAreaFilterQuery,
          limit,
          user
        );
    }

    // set input field empty
    setLimit('');
  };

  // Geting data with limit
  const handleGetingDataWithLimit = (e, page) => {
    if (e) {
      e.preventDefault();
    }

    setcurrentPage(page);

    //sheet area page or not
    if (location.pathname.includes('sheets')) {
      location.pathname.includes('lead-server') &&
        getDataViaPageSheets(page, all_sheetsFilterQuery, limit);
    } else {
      location.pathname.includes('lead-server') &&
        getDataViaPageLeads(page, leadServerFilterQuery, limit);
      location.pathname.includes('adminstration') &&
        getDataViaPageUsers(page, adminstrationFilterQuery, limit);
      location.pathname.includes('client-area') &&
        getDataViaPageClient(page, clientAreaFilterQuery, limit, user);
    }

    // set input field empty
    setLimit('');
  };

  // Page count depends on all leads reasult
  if (allFilteredLeads.allFilteredLeads !== 0) {
    return (
      <Wrapper>
        <ReactPaginate
          previousLabel={
            <FaAngleLeft
              style={{ fontSize: '25px', fill: '#fff', color: '#fff' }}
            />
          }
          nextLabel={
            <FaAngleRight
              style={{ fontSize: '25px', fill: '#fff', color: '#fff' }}
            />
          }
          breakLabel={'...'}
          pageCount={allPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handleGetingData}
          containerClassName={'Wrapper'}
          pageClassName={''}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'pre-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'next-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
        />

        {user.role === 'admin' ? (
          <form action="" onSubmit={e => handleGetingDataWithLimit(e, 1)}>
            <input
              type="tel"
              onClick={() => alert('Limit only work with filtered data.')}
              value={limit}
              className="limit_input"
              placeholder="Limit"
              onChange={e => setLimit(Number(e.target.value))}
            />
          </form>
        ) : (
          ''
        )}
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <p>There are no page available, Please Search Correctly :)</p>
      </Wrapper>
    );
  }
};
const Wrapper = styled.div`
  display: flex;
  margin-left: 9.3%;

  .Wrapper {
    display: flex;
    list-style: none;
    margin: 15px 0px;
  }
  p {
    cursor: pointer;
    margin-right: 0.5rem;
    font-size: initial;
    font-weight: 700;
    color: black;
    padding: 0.1rem 0.4rem 0.1rem 0.4rem;
    background-color: #80808059;
  }
  .page-link {
    cursor: pointer;
    margin-right: 0.5rem;
    font-size: initial;
    font-weight: 700;
    padding: 10px;
    fill: #fff !important;
    stroke: #fff;
    background-color: #60a5fa;
    color: #fff;
    border-radius: 5px;
  }
  .next-link,
  .pre-link {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    background-color: #60a5fa;
    border-radius: 5px;
    cursor: pointer;
    margin: -10px 15px 0px 0px;
  }
  .limit_input {
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 30%;
    text-align: center;
    color: #60a5fa;
    border: 2px solid #60a5fa;
    height: 25%;
    outline: none;
    border-radius: 5px;
    margin-top: 0.4rem;
  }
  .limit_input::placeholder{
    color: #60a5fa;
  }

  .btn {
    margin-top: 0.9rem;
    margin-right: 0.5rem;
    cursor: pointer;
    height: 50%;
    font-weight: 700;
    color: gray;
    font-size: 1rem;
  }
  .active > a {
    cursor: pointer;
    font-size: initial;
    font-weight: 700;
    color: #fff;
    border-radius: 5px;
    background-color: #0284c7 !important;
  }
`;
