const all_sheets_reducer = (state, action) => {

    if(action.type === 'getAllSheetsLoading') {
        return {...state, sheetsLoading: true}
    }
    if(action.type === 'getAllSheetsSuccess') {
        return {...state, sheetsLoading: false, sheets: action.payload.sheets, allSheets: action.payload.allSheetsResults, filterSheets: [], filterQuery: ''}
    }
    if(action.type === 'updateFilterDataLoading') {
        return {...state, filterLeads_loading: true}
    }

    if(action.type === 'getSheetFilterDataSuccess') {
        return {...state, sheets:[], filterSheets: action.payload.filteredData, allSheets: action.payload.allFilteredData, filterQuery: action.payload.filterQuery, filterLeads_loading: false}
    }

}

export default all_sheets_reducer;