import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminstrationUser from "./pages/AdminstrationUser";
import { ClientArea } from "./pages/ClientArea";
import { ClientSheet } from "./pages/ClientSheets";
import Dashboard from "./pages/Dashboard";
import Employee from "./pages/Employee";
import Home from "./pages/Home";
import LeadServer from "./pages/LeadServer";
import { LeadsSheet } from "./pages/LeadsSheet";
import Login from "./pages/Login";
import { PrivateRoute } from "./pages/PrivateRoute";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import SpecificClientSheet from "./pages/SpecificClientSheet";


function App() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/" exact={true} children={<Dashboard />} />
        <PrivateRoute path="/employee" exact={true} children={<Employee />} />
        <PrivateRoute path="/dashboard" exact={true} children={<Dashboard />} />
        <PrivateRoute
          path="/adminstration"
          exact={true}
          children={<AdminstrationUser />}
        />
        <PrivateRoute
          path="/lead-server"
          exact={true}
          children={<LeadServer />}
        />
        <PrivateRoute
          path="/seecific-lead-server/:clientId/:sheetName"
          exact={true}
          children={<SpecificClientSheet />}
        />
        <PrivateRoute
          path="/client-area"
          exact={true}
          children={<ClientArea />}
        />
        <PrivateRoute
          path="/client-area/sheets"
          exact={true}
          children={<ClientSheet />}
        />
        <PrivateRoute
          path="/lead-server/sheets"
          exact={true}
          children={<LeadsSheet />}
        />
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/signup">
          <Register />
        </Route>
        <Route exact path="/forget-password">
          <ForgetPassword />
        </Route>
        <Route path="/reset-password/:id/:token">
          <ResetPassword />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
