import React, { useEffect, useContext, useReducer } from "react";
import reducer from "../reducers/login_reducer.js";
import { hostAddress } from "../utils/helpers";
import axios from "axios"; 

const initialState = {
  isAuthenticated: false,
  active: 0,
  user: {},
  isExpend: false,
  mode: "light",
};

const LoginContext = React.createContext();

export const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const userIntercting = () => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      return "";
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${hostAddress}/users/me`, config)
      .then((res) => {
        const user = res.data.data.doc;

        if (user !== undefined) {
          dispatch({ type: "setUser", payload: user });
        }
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.request.response);
        alert(errorMessage.message);
      });
  };
  useEffect(() => {
    userIntercting();
  }, []);

  const allowUser = (user) => {
    dispatch({ type: "allowUser" });
  };

  const checkingAuthentication = async (emailOrId, password) => {
    try {
      const res = await axios({
        method: "POST",
        url: `${hostAddress}/users/login`,
        data: {
          emailOrId,
          password,
          withCredentials: true,
        },
      });
      const user = res.data.data.user;
      const token = res.data.token;
      localStorage.setItem("jwt", token);
      localStorage.setItem("isAuthenticated", true)

      if (token) {
        dispatch({ type: "setUser", payload: user });
        allowUser(user);
      }
    } catch (error) {
      const errorMessage = JSON.parse(error.request.response);
      alert(errorMessage.message);
    }
  };
  const userRegistration = async (name, email, password, passwordConfirm) => {
    try {
      const res = await axios({
        method: "POST",
        url: `${hostAddress}/users/signup`,
        data: {
          name,
          email,
          password,
          passwordConfirm,
          createdDateShow: new Date().toLocaleString('en-US'),
          withCredentials: true,
        },
      });
      const user = res.data.data.user;
      const token = res.data.token;
      localStorage.setItem("jwt", token);

      if (token) {
        dispatch({ type: "setUser", payload: user });
        allowUser(user);
      }
    } catch (error) {
      const errorMessage = JSON.parse(error.request.response);
      alert(errorMessage.message);
    }
  };

  // Logout
  const logoutUser = () => {
    localStorage.removeItem('isAuthenticated')
    dispatch({ type: "logout" });
  };
  const updateActiveClass = (index) => {
    dispatch({ type: "updateActiveClass", payload: index });
  };

  const OpenSidebar = () => {
    dispatch({ type: "openSidebar" });
  };
  const CloseSidebar = () => {
    dispatch({ type: "closeSidebar" });
  };
  const LightMode = () => {
    dispatch({ type: "lightmode" });
  };
  const DarkMode = () => {
    dispatch({ type: "darkmode" });
  };
  

  return (
    <LoginContext.Provider
      value={{
        ...state,
        checkingAuthentication,
        userRegistration,
        logoutUser,
        allowUser,
        updateActiveClass,
        OpenSidebar,
        CloseSidebar,
        LightMode,
        DarkMode,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
// make sure use
export const useLoginContext = () => {
  return useContext(LoginContext);
};
