// import ColumnFilter from "./ColumnFilter";

export const AdminstrationColumns = [
  {
    Header: "ID",
    accessor: "_id",
    sticky: "left",
    // Filter: ColumnFilter,
  },
  {
    Header: "Client ID",
    accessor: "userId",
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: "Name",
    accessor: "name",
    sticky: "left",
    // Filter: ColumnFilter,
  },
  {
    Header: "Role/Type",
    accessor: "role",
    sticky: "left",
    // Filter: ColumnFilter,
  },
  {
    Header: "Email",
    accessor: "email",
    sticky: "left",
    // Filter: ColumnFilter,
  },
  {
    Header: "Created Data",
    accessor: "createdDateShow",
    // Filter: ColumnFilter,
    sticky: 'left'
  },
  {
    Header: "N/A",
    accessor: "companyName",
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: "N/A",
    accessor: "companyPhone",
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: "N/A",
    accessor: "tag",
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: "N/A",
    accessor: "fullAddress",
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: "N/A",
    accessor: "sourceLink",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "namePrefix",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "firstName",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "middleName",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "lastName",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "sureName",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "gender",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "personalPhone",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "employeeRange",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "companyWebsite",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "companyLinkedinLink",
    // Filter: ColumnFilter,
  },

  {
    Header: "N/A",
    accessor: "companyHeadCount",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "companyHQAddress",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "industry",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "revenue",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "street",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "city",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "state",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "country",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "zip",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "rating",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "sheetID",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "sheetName",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "customField1",
    // Filter: ColumnFilter,
  },
  {
    Header: "N/A",
    accessor: "customField2",
    // Filter: ColumnFilter,
  },
  // if need to active or delete lead
  //   {
  //     Header: "Active/Delete",
  //     accessor: "isActive",
      // Filter: ColumnFilter,
  //   },
  {
    Header: "N/A",
    accessor: "isDeleted",
    // Filter: ColumnFilter,
  },
];
