import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLoginContext } from '../context/login&Register_context';
import { Link, Redirect } from 'react-router-dom';
import '../fonts&Styles/SignIn&SignUp.css';

const Register = () => {
  const { userRegistration, isAuthenticated } = useLoginContext();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setpasswordConfirm] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    userRegistration(name, email, password, passwordConfirm);
  };

  // if there is token not allow to access this page
  const token = localStorage.getItem('jwt');
  if (token && !isAuthenticated) {
    alert('Please logout to access signup / To Go Dashboard Press Ok');
    return (
      <div>
        <Redirect to="/dashboard">Go</Redirect>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Wrapper className="account">
        {/* checking Form */}
        <div className="section-signin">
          <h1 className="section-signin_header">Create An Account</h1>
          <p className="section-signin_para">
            Create an account for joining with us. Khalid IT
          </p>
          <form
            className="section-signin_form form-singup"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              name="name"
              className="section-signin_form-input input-singup"
              onChange={e => setName(e.target.value)}
              placeholder="Name"
              required
            />

            <br />
            <input
              type="email"
              name="email"
              className="section-signin_form-input input-singup"
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <br />
            <input
              type="password"
              name="customer[password]"
              id="customerpassword"
              onChange={e => setPassword(e.target.value)}
              className="section-signin_form-input input-singup"
              placeholder="Password"
              required
            />
            <br />
            <input
              type="password"
              name="customer[password]"
              id="customerpassword"
              required
              onChange={e => setpasswordConfirm(e.target.value)}
              className="section-signin_form-input input-singup"
              placeholder="Password Confirm"
            />
            <br />

            <input
              type="submit"
              className="section-signin_form-submit"
              value="SIGN UP"
            />
            <p className="section-signin_form-signup">
              Already have an account? &nbsp;
              <Link to="/login" className="section-signin_form-signup--link">
                Sign in
              </Link>
            </p>
          </form>
        </div>
        {/* checking Form end*/}
      </Wrapper>
    );
  } else {
    <div>
      <Redirect to="/dashboard">Go</Redirect>
    </div>;
  }
};

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #400040;
  height: 100vh;
`;

export default Register;
