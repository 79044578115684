import React, { useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeProviderToPages } from '../components/ThemeProvider';
import { LeadServerCOLUMNS } from '../components/table/tableComponents/LeadServerColumns';
import { useLeadServerContext } from '../context/lead_server_context';
import navData from '../context/mockdata/nav-contents-admin';

function LeadServer() {
  let {
    leads: leadData,
    specific,
    allLeadsResults,
    leads_loading: loading,
    filterLeads_loading,
  } = useLeadServerContext();
  const { filterLeads: filterleadData } = useLeadServerContext();
  const { clientId, sheetName } = useParams();
  useEffect(() => {
    specific(clientId, sheetName);
  }, [clientId]);

  const filteredUpto50Lead = [];

  // Sending only 49 filtered Lead to Leadserver UI

  filterleadData.forEach((element, i) => {
    if (i < 49) {
      filteredUpto50Lead.push(element);
    }
  });

  if (filteredUpto50Lead.length > 0) {
    leadData = filteredUpto50Lead;
  }

  return (
    <Wrapper>
      <ThemeProviderToPages
        TableData={leadData}
        allFilteredLeads={allLeadsResults}
        filterLoading={filterLeads_loading}
        Columns={LeadServerCOLUMNS}
        Reset={specific}
        navData={navData}
        loading={loading}
        title={'Welcome To Lead Server '}
      />
    </Wrapper>
  );
}

const Wrapper = styled.section``;
export default withRouter(LeadServer);
