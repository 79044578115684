import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PageStructureOne from "./DefaultPageStructure/PageStructure1";
import navData from "../context/mockdata/nav-contents-admin";
import { useLeadServerContext } from "../context/lead_server_context";
import { ThemeProviderToPages } from "../components/ThemeProvider";
import {LeadServerCOLUMNS} from '../components/table/tableComponents/LeadServerColumns';


function LeadServer() {
  let { leads: leadData, fetchLeads, allLeadsResults, leads_loading: loading,  filterLeads_loading } = useLeadServerContext();
  const { filterLeads: filterleadData} = useLeadServerContext();

  useEffect(() => {
    fetchLeads();
  }, []);

  console.log(filterleadData);

  const filteredUpto50Lead = [];

  // Sending only 49 filtered Lead to Leadserver UI
  filterleadData.forEach((element, i) => {
    if (i < 49) {
      filteredUpto50Lead.push(element);
    }
  });

  if (filteredUpto50Lead.length > 0) {
    leadData = filteredUpto50Lead;
  }

  return (
    <Wrapper>
      <ThemeProviderToPages
        TableData={leadData}
        allFilteredLeads={allLeadsResults}
        filterLoading={filterLeads_loading}
        Columns={LeadServerCOLUMNS}
        Reset={fetchLeads}
        navData={navData}
        loading={loading}
        title={"Welcome To Lead Server"}
      />
    </Wrapper>
  );
}

const Wrapper = styled.section``;
export default withRouter(LeadServer);
