import React from "react";
import styled from "styled-components";
import TableData from "./tableComponents/TableData";


function Table({ Reset, tableData, allFilteredLeads, Columns}) {
  if (tableData !== undefined) {
    return (
      <Wrapper>
        <TableData
          Reset={Reset}
          actualData={tableData}
          allFilteredLeads={allFilteredLeads}
          Columns={Columns}
        ></TableData>
      </Wrapper>
    );
  } else {
    return '';
  }
}

const Wrapper = styled.div`
  
`

export default Table;
