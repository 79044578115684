import React, { useContext, useReducer } from "react";
import reducer from "../reducers/adminstration_reducer"
import { hostAddress, getToken } from "../utils/helpers";
import axios from 'axios'
import { getDataViaPageResponce, getFilterDataResponce, globalFilterResponce } from "./context_helpers/handleFactory";


const initialState = {
    usersLoading: false,
    users: [],
    allUsers: 0,
    filterUsers_loading: false,
    filterUsers: [],
    filterQuery: '',
    uploadSheet_loading: 0,
}


const AdminstrationContext = React.createContext();

export const AdminstrationProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

  // Model meaning indicate the filter table on backend
  const model = 'users'

    const getAllUser = async () => {
      dispatch({
        type: 'getUsersLoading'
      });
        try {
            const token = getToken();
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            
            const responce = await axios.get(`${hostAddress}/users`, config);
            const users = responce.data.data.docs;
            const allUsers = responce.data.allDocs;
      
            dispatch({
              type: 'getUsersSuccess',
              payload: {users, allUsers},
            });

            
          } catch (error) {
            const errorMessage = JSON.parse(error.request.responce);
            alert(errorMessage.message);
            // dispatch({ type: "getLeadsProductsError" });
          }
    }

    const createUser = async (name, email, role, userId, password, passwordConfirm) => {
        try {
            const token = getToken();
            // const config = {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // };
       

            const res = await axios({
                method: "POST",
                url: `${hostAddress}/users`,
                data: {
                    name,
                    email,
                    password,
                    passwordConfirm,
                    role,
                    userId,
                    withCredentials: true,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });

              const user = res.data.data.data;

         

             // show success and user data into the alert
              alert(`
              ${res.data.status}\n
              Role: ${user.role}\n
              Name: ${user.name}\n
              Name: ${user.email}\n
              `);
            
        } catch (error) {
            const errorMessage = JSON.parse(error.request.responseText)
            alert(errorMessage.message)
            
        }
    }

    const updateFilterData = async (filterQuery) => {
  
        dispatch({ type: "updateFilterDataLoading" });
  
      const {filteredData, allFilteredData} = await getFilterDataResponce(filterQuery, model);
      
      dispatch({
              type: "getUsersFilterDataSuccess",
              payload: { filteredData, allFilteredData, filterQuery},
       });
  

      }

      const getDataViaPage = async (page, filterQuery, limit) => {
    
        dispatch({ type: "updateFilterDataLoading" });
        
      const {filteredData, allFilteredData} = await getDataViaPageResponce(model, page, filterQuery, limit);
  
            dispatch({
          type: "getUsersFilterDataSuccess",
          payload: { filteredData, allFilteredData, filterQuery},
        });
    }

     // Global filter
  const globalFilter = async (filterQueryGlobal) => {
    dispatch({ type: "updateFilterDataLoading" });
  
    const {filteredData, allFilteredData} = await globalFilterResponce(model, filterQueryGlobal);

    //Remake filter query for show to Your show filter
    const filterQuery = `globalfilter?${filterQueryGlobal}`

       dispatch({
      type: "getUsersFilterDataSuccess",
      payload: { filteredData, allFilteredData, filterQuery},
    });

  }


    return (
        <AdminstrationContext.Provider value={{...state, getAllUser, createUser, updateFilterData, getDataViaPage, globalFilter}}>{children}</AdminstrationContext.Provider>
    )
}

// make sure use
export const useAdminstrationContext = () => {
    return useContext(AdminstrationContext)
}