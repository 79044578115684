// import ColumnFilter from "./ColumnFilter";

export const AllSheetsColumn = [
  {
    Header: 'Sheet ID',
    accessor: 'sheetID',
    sticky: 'left',
    // Filter: ColumnFilter,
  },
  {
    Header: 'Client ID',
    accessor: 'clientId',
    sticky: 'left',
    // Filter: ColumnFilter,
  },
  {
    Header: 'Sheet Name',
    accessor: 'sheetName',
    sticky: 'left',
    // Filter: ColumnFilter,
  },
  {
    Header: 'Project Name',
    accessor: 'projectName',
    sticky: 'left',
    // Filter: ColumnFilter,
  },
  {
    Header: 'Working Date',
    accessor: 'sheetWorkingDate',
    sticky: 'left',
    // Filter: ColumnFilter,
  },

  {
    Header: 'Created Data',
    accessor: 'createdDate',
    // Filter: ColumnFilter,
    sticky: 'left',
  },
  {
    Header: 'N/A',
    accessor: 'userId',
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe2',
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe3',
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe4',
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe5',
    // Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe6',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe7',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe8',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe9',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe10',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe11',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe12',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe13',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe14',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe15',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe16',
    // Filter: ColumnFilter,
  },

  {
    Header: 'N/A',
    accessor: 'notAvailabe17',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe18',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe19',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe20',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe21',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe22',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe23',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe24',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe25',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe26',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe27',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: '',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe28',
    // Filter: ColumnFilter,
  },
  {
    Header: 'N/A',
    accessor: 'notAvailabe29',
    // Filter: ColumnFilter,
  },
  // if need to active or delete lead
  //   {
  //     Header: "Active/Delete",
  //     accessor: "isActive",
  // Filter: ColumnFilter,
  //   },
  {
    Header: 'Action',
    accessor: 'deleteAction',
    // Filter: ColumnFilter,
  },
];
  