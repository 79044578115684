import React, { useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/lead_server_reducer';
import { hostAddress, getToken } from '../utils/helpers';
import axios from 'axios';
import {
  getFilterDataResponce,
  getDataViaPageResponce,
  globalFilterResponce,
} from '../context/context_helpers/handleFactory';
import { useClientAreaContext } from './client_area_context';

const initialState = {
  leads_loading: false,
  leads_error: false,
  allLeadsResults: 0,
  leads: [],
  filterLeads_loading: false,
  filterLeads: [],
  filterQuery: '',
  uploadSheet_loading: 0,
};

const LeadServerContext = React.createContext();

export const LeadServerProvider = ({ children }) => {
  // Don't {} intead of []
  const [state, dispatch] = useReducer(reducer, initialState);

  // Model meaning indicate the filter table on backend
  const model = 'leads';

  const specific = async (clientId, sheetName) => {
    dispatch({ type: 'getLeadsProductLoading' });
    const data = clientId.split('-');
  
    var fatchData = '';
    if (sheetName == 'userId') {
      fatchData = data[0];
    
    } else {
       fatchData = data[1];
    }
    
    try {
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const responce = await axios.get(`${hostAddress}/leads/specific`, {
        params: { sheetName, clientId: fatchData },
        ...config,
      });
      const leads = responce.data.data.docs;
      const allleadsResuls = responce.data.allDocs;

      dispatch({
        type: 'getLeadsProductSuccess',
        payload: { leads, allleadsResuls },
      });
    } catch (error) {
      const errorMessage = JSON.parse(error.request.responce);
      alert(errorMessage.message);
      dispatch({ type: 'getLeadsProductsError' });
    }
  };

  // Get 50 Lead data
  const fetchLeads = async () => {
    dispatch({ type: 'getLeadsProductLoading' });

    try {
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const responce = await axios.get(`${hostAddress}/leads`, config);
      const leads = responce.data.data.docs;
      const allleadsResuls = responce.data.allDocs;
      dispatch({
        type: 'getLeadsProductSuccess',
        payload: { leads, allleadsResuls },
      });
    } catch (error) {
      const errorMessage = JSON.parse(error.request.responce);
      alert(errorMessage.message);
      dispatch({ type: 'getLeadsProductsError' });
    }
  };

  // Sheet upload
  const uploadSheets = async (file, sheetWorkingDate, clientId, sheetname) => {
    const token = getToken();

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: progress => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;

          dispatch({ type: 'uploadSheetLoading', payload: uploadPercentage });

          if (totalSizeInMB < 0.00015) {
            alert('Please upload a file');
          }
        },
      };

      const data = new FormData();
      data.append('file', file);
      data.append('sheetWorkingDate', sheetWorkingDate);
      data.append('clientId', clientId);
      data.append('sheetName', sheetname);
      const responce = await axios.post(
        `${hostAddress}/leads/upload`,
        data,
        config
      );
      if (responce) {
        const status = responce.data.status;
        const totalDoccument = responce.data.totalDoccuments;
        alert(`${status}, Document: ${totalDoccument}`);
      }
    } catch (error) {
      const errorMessage = JSON.parse(error.request.response);
      alert(errorMessage.message);
    }
  };

  //Filter lead data
  const updateFilterData = async filterQuery => {
    dispatch({ type: 'updateFilterDataLoading' });

    const { filteredData, allFilteredData } = await getFilterDataResponce(
      filterQuery,
      model
    );
    if (filteredData === '') {
      dispatch({ type: 'updateFilterDataLoadingStop' });
      return '';
    }

    dispatch({
      type: 'getLeadsFilterDataSuccess',
      payload: { filteredData, allFilteredData, filterQuery },
    });
  };

  // Global filter
  const globalFilter = async filterQueryGlobal => {
    dispatch({ type: 'updateFilterDataLoading' });

    const { filteredData, allFilteredData } = await globalFilterResponce(
      model,
      filterQueryGlobal
    );

    //Remake filter query for show to Your show filter
    const filterQuery = `globalfilter?${filterQueryGlobal}`;

    dispatch({
      type: 'getLeadsFilterDataSuccess',
      payload: { filteredData, allFilteredData, filterQuery },
    });
  };

  // Data via page call
  const getDataViaPage = async (page, filterQuery, limit) => {
    dispatch({ type: 'updateFilterDataLoading' });

    let responce;
    responce = await getDataViaPageResponce(model, page, filterQuery, limit);

    if (responce) {
      const { filteredData, allFilteredData } = responce;
      dispatch({
        type: 'getLeadsFilterDataSuccess',
        payload: { filteredData, allFilteredData, filterQuery },
      });
    } else {
      return '';
    }
  };

  return (
    <LeadServerContext.Provider
      value={{
        ...state,
        updateFilterData,
        uploadSheets,
        fetchLeads,
        getDataViaPage,
        globalFilter,
        specific,
      }}
    >
      {children}
    </LeadServerContext.Provider>
  );
};

//make sure use
export const useLeadServerContext = () => {
  return useContext(LeadServerContext);
};
