const adminstration_reducer = (state, action) => {
    if(action.type === 'getUsersSuccess') {
        return {...state, users: action.payload.users, allUsers: action.payload.allUsers, usersLoading: false, filterUsers:[], filterQuery: ''}
    }
    if (action.type === 'getUsersLoading') {
        return {...state, usersLoading: true}
    }
    if(action.type === 'getUsersFilterDataSuccess') {
        return {...state, filterUsers_loading: false, filterUsers: action.payload.filteredData, filterQuery: action.payload.filterQuery, allUsers: action.payload.allFilteredData, users: []}
    }
    if (action.type === 'updateFilterDataLoading') {
        return {...state, filterUsers_loading: true}
    }
}

export default adminstration_reducer;