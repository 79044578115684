import React, { useState } from 'react';
import styled from 'styled-components';
import { useLeadServerContext } from '../../../context/lead_server_context';
import { SendingQueryString } from './TableData';
import { AiOutlineSearch } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import {
  checkingFilterColumnDublicate,
  checkingFilterValue,
  checkingInputIsRight,
} from '../../../utils/helpers';
import { useAdminstrationContext } from '../../../context/adminstration_context';
import { useClientAreaContext } from '../../../context/client_area_context';
import { useAllSheetsContext } from '../../../context/all_sheets_context';
import { column } from '../../../fonts&Styles/styled-components/ColumnFilterStyled';

const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  const {
    filterQuery: leadServerFilterQuery,
    updateFilterData: updateFilterDataLeads,
  } = useLeadServerContext();
  const {
    filterQuery: adminstrationFilterQuery,
    updateFilterData: updateFilterDataUsers,
  } = useAdminstrationContext();
  const {
    filterQuery: clientAreaFilterQuery,
    updateFilterData: updateFilterDataClient,
  } = useClientAreaContext();
  const {
    filterQuery: allSheetsFilterQuery,
    updateFilterData: updateFilterDataAllSheets,
  } = useAllSheetsContext();

  let location = useLocation();

  const updateLeadServerFilter = e => {
    e.preventDefault();

    // geting queryString form tableData
    const queryString = SendingQueryString();

    // If we have old filter query or not and call with the filter query start
    if (
      leadServerFilterQuery ||
      adminstrationFilterQuery ||
      clientAreaFilterQuery ||
      allSheetsFilterQuery
    ) {
      let newFilterValue = queryString.replace('?', '&');

      const leadServerFilterValue = checkingFilterColumnDublicate(
        leadServerFilterQuery,
        newFilterValue
      );
      const adminstrationFilterValue = checkingFilterColumnDublicate(
        adminstrationFilterQuery,
        newFilterValue
      );
      const allSheetFilterValue = checkingFilterColumnDublicate(
        allSheetsFilterQuery,
        newFilterValue
      );
      const clientAreaFilterValue = checkingFilterColumnDublicate(
        clientAreaFilterQuery,
        newFilterValue
      );

      // const leadServerFilterValue = `${leadServerFilterQuery}${newFilterValue}`
      // const adminstrationFilterValue = `${adminstrationFilterQuery}${newFilterValue}`
      // const allSheetFilterValue = `${allSheetsFilterQuery}${newFilterValue}`;

      //checking input value
      const sanitizedValue = checkingInputIsRight(filterValue);
      console.log(sanitizedValue);
      if (sanitizedValue === 'Error') {
        setFilter('');
        alert('Please Provide meaningfull filter text');
      } else {
        if (location.pathname.includes('sheet')) {
          location.pathname === '/lead-server/sheets' &&
            updateFilterDataAllSheets('?' + allSheetFilterValue.slice(1));
          location.pathname === '/client-area/sheets' &&
            updateFilterDataAllSheets(allSheetFilterValue);
        } else {
          location.pathname.includes('lead-server') &&
            updateFilterDataLeads('?' + leadServerFilterValue.slice(1));

          location.pathname.includes('adminstration') &&
            updateFilterDataUsers('?' + adminstrationFilterValue.slice(1));

          location.pathname.includes('client-area') &&
            updateFilterDataClient(clientAreaFilterValue);
        }
      }
    } else {
      //checking input value
      const sanitizedValue = checkingInputIsRight(filterValue);

      if (sanitizedValue === 'Error') {
        setFilter('');
        alert('Please Provide meaningfull filter text');
      } else {
        // if location include sheet then excute sheets feature
        if (location.pathname.includes('sheet')) {
          //sheets
          location.pathname === '/lead-server/sheets' &&
            updateFilterDataAllSheets(queryString);
          location.pathname === '/client-area/sheets' &&
            updateFilterDataAllSheets(queryString);
        } else {
          location.pathname.includes('lead-server') &&
            updateFilterDataLeads(queryString);
          location.pathname.includes('adminstration') &&
            updateFilterDataUsers(queryString);
          location.pathname.includes('client-area') &&
            updateFilterDataClient(queryString);
        }
      }
    }
    // If we have old filter query or not and call with the filter query end

    // clear Filter input value
    setFilter('');
  };
  return (
    <Wrapper>
      <form className="search-form" onSubmit={updateLeadServerFilter}>
        <input
          className='input-field'
          value={filterValue || ''}
          onChange={e => setFilter(e.target.value)}
        />
        <button type="submit" value="Submit" className="search-btn">
          <AiOutlineSearch />
        </button>
      </form>
    </Wrapper>
  );
};

export default ColumnFilter;

const Wrapper = column;
