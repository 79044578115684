const lead_server_reducer = (state, action) => {
  if (action.type === "getLeadsProductLoading") {
    return { ...state, leads_loading: true };
  }
  if (action.type === "getLeadsProductSuccess") {
    return {
      ...state,
      leads: action.payload.leads,
      leads_loading: false,
      allLeadsResults: action.payload.allleadsResuls,
      filterLeads_loading: false,
      filterQuery: '',
      filterLeads: [],
    };
  }
  if (action.type === "getLeadsProductsError") {
    return { ...state, leads_error: true };
  }
  if (action.type === "updateFilterDataLoading") {
    return {
      ...state,

      filterLeads_loading: true,
    };
  }
  if (action.type === 'updateFilterDataLoadingStop') {
    return {...state, filterLeads_loading: false}
  }

  if (action.type === "uploadSheetLoading") {
    return { ...state, uploadSheet_loading: action.payload };
  }
  if (action.type === "getLeadsFilterDataSuccess") {
    return {
      ...state,
      leads: [],
      filterLeads: action.payload.filteredData,
      allLeadsResults: action.payload.allFilteredData,
      filterQuery: action.payload.filterQuery,
      filterLeads_loading: false,
    };
  }


  // if(action.type === 'getLeadsFilterDataSuccessViaPage') {
  //   return {...state,filterLeads: ac}
  // }
  return state;
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default lead_server_reducer;
