import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { getToken, hostAddress } from '../utils/helpers';
function ForgetPassword() {
  const [successMessgae, setSuccessMessage] = useState(null);
  const [errorMessgae, setErrorMessage] = useState(null);
  useEffect(() => {
    setInterval(() => {
      setErrorMessage(null);
    }, 3000);
  }, []);
  const submitHandler = e => {
    e.preventDefault();
    axios
      .post(`${hostAddress}/forget/forget-password`, {
        email: e.target[0].value,
        server_link: 'http://localhost:3000',
      })
      .then(res => {
        if (res.data.name === 'success') {
          setSuccessMessage("Email sent successfully. go to your gmail account");
        }
       
      })
      .catch(e => {
        setErrorMessage('Email Not Found');
      });
  };

  return (
    <>
      <GlobalStyles />
      {successMessgae ? (
        <Div>
          <ChildDiv>
            <IoIosCheckmarkCircle
              style={{
                textAlign: 'center',
                display: 'block',
                margin: 'auto',
                fontSize: '100px',
              }}
            />
            <h2 style={{ fontWeight: 600 }}>{successMessgae}</h2>
          </ChildDiv>
        </Div>
      ) : (
        <Div>
          <Form onSubmit={submitHandler}>
            {errorMessgae && <ErrorMessage>{errorMessgae}</ErrorMessage>}
            <EmailInput
              type="email"
              required
              placeholder="Enter Your Login Email"
            ></EmailInput>
            <Button>Sent Me</Button>
            <div className="bg-red-300"></div>
          </Form>
        </Div>
      )}
    </>
  );
}
const GlobalStyles = createGlobalStyle`
 font-family: sans-serif;
`;
const ErrorMessage = styled.div`
  width: 100%;
  color: #b91c1c;
  background-color: #fecaca;
  margin: 10px 0px;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
`;
const Div = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ChildDiv = styled.div`
  padding: 25px;
  background: #d1e7dd;
  color: #0a3622;
  text-align: center;
  border-radius: 5px;
`;
const Form = styled.form`
  display: block;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px #ffff;
  padding: 40px;
  border-radius: 5px;
`;
const Input = styled.input`
  background: none;
  padding: 12px;
  outline: none;
  border: none;
`;
const EmailInput = styled(Input)`
  cursor: pointer;
  border: 1px solid black;
  border-radius: 8px;
  margin: 0px 10px 0px 0px;
`;
const Button = styled.button`
  padding: 10px;
  border: none;
  background: #0276e5;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
`;

export default ForgetPassword;
