import styled from 'styled-components';

export const column = styled.section`
  .search-form {
    display: flex;
    padding: 5px 0px;
  }

  .input-field {
    margin-top: 5px;
    width: 80%;
    padding: 4px 10px;
    border-radius: 15px;
  }
  .search-btn {
    border: none;
    font-size: 1.25rem;
    color: #3f3f3f;
    margin-left: -2rem;
    margin-bottom: -0.5rem;
    background: none;
    cursor: pointer;
    z-index: 1;
  }
`;
