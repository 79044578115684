const client_area_reducer = (state, action) => {
    if(action.type === 'getClientLeadsSuccess') {
        return {...state, clientLeads: action.payload.leads,allFilteredLeads: action.payload.allFilteredLeads, clientLeadsLoading: false,filterClient_loading: false, filterClientLeads: [], filterQuery: action.payload.filterQuery}
    }
    if(action.type === "getClientLeadsLoading") {
        return {...state, clientLeadsLoading: true}
    }
    if(action.type === 'updateFilterDataLoading') {
        return {...state, filterClient_loading: true}
    }

    if(action.type === 'updateFilterDataLoadingStop') {
        return {...state, filterClient_loading: false}
    }

    if(action.type === 'getClientFilterDataSuccess') {
        return {...state, filterClient_loading: false, filterClientLeads: action.payload.filteredData, allFilteredLeads: action.payload.allFilteredData, filterQuery: action.payload.filterQuery, clientLeads: []}
    }
    if(action.type === 'cleanQuery') {
        return {...state, filterQuery: ''}
    }
};

export default client_area_reducer;