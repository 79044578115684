import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useAsyncDebounce } from 'react-table';
import styled from 'styled-components';
import { useAdminstrationContext } from '../../../context/adminstration_context';
import { useClientAreaContext } from '../../../context/client_area_context';
import { useLeadServerContext } from '../../../context/lead_server_context';
import { checkingInputIsRight } from '../../../utils/helpers';

export const GlobalFilter = () => {
  const [value, setValue] = useState('');
  const { globalFilter: globalFilterLeads, specific } = useLeadServerContext();
  const { globalFilter: globalFilterUsers } = useAdminstrationContext();
  const { globalFilter: globalFilterClient } = useClientAreaContext();

  // for filter globally on table start
  // If data will more and more increase the milisecond to 2000 or 3000
  // const onChange = useAsyncDebounce(value => {
  //     setFilter(value || undefined)
  // }, 1000)
  // for filter globally on table end

  const location = useLocation();

  const handleSubmit = e => {
    e.preventDefault();

    //checking input value
    const sanitizedValue = checkingInputIsRight(value);

    if (sanitizedValue === 'Error') {
      alert('Please Provide meaningfull filter text');
    } else {
      location.pathname.includes('lead-server') &&
        globalFilterLeads(sanitizedValue);
      location.pathname.includes('adminstration') &&
        globalFilterUsers(sanitizedValue);
      location.pathname.includes('client-area') &&
        globalFilterClient(sanitizedValue);
    }
    setValue('');
  };

  const [sheetNameValue, setSheetNamevalue] = useState('');
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: '0px 10px',
        }}
      >

        <span>
          <form onSubmit={e => handleSubmit(e)}>
            <input
              value={value || ''}
              onChange={e => {
                setValue(e.target.value);
              }}
              placeholder="Global Filter"
            />
          </form>
        </span>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  span {
    display: block;
    margin-bottom: 1%;
  }
  input {
    font-size: 1rem;
    padding: 0.4rem 0.7rem;
    outline: none;
    border: 1.5px solid #78a7ab;
    border-radius: 20px;
  }
`;
