import ColumnFilter from "./ColumnFilter";

export const LeadServerCOLUMNS = [
  {
    Header: 'Linked In Link',
    accessor: 'linkedinLink',
    sticky: 'left',
    Filter: ColumnFilter,
  },
  {
    Header: 'Company name',
    accessor: 'companyName',
    Filter: ColumnFilter,
    sticky: 'left',
    // sticky: 'left'
  },
  {
    Header: 'Contact Name',
    accessor: 'contactName',
    Filter: ColumnFilter,
    sticky: 'left',
    // sticky: 'left'
  },
  {
    Header: 'First name',
    accessor: 'firstName',
    Filter: ColumnFilter,
    sticky: 'left',
  },
  {
    Header: 'Last name',
    accessor: 'lastName',
    Filter: ColumnFilter,
    sticky: 'left',
  },
  {
    Header: 'Email Address',
    accessor: 'emailAddress',
    Filter: ColumnFilter,
    sticky: 'left',
  },
  {
    Header: 'Title/Position',
    accessor: 'titlePosition',
    Filter: ColumnFilter,
  },
  {
    Header: 'Person Location',
    accessor: 'personalLocation',
    Filter: ColumnFilter,
  },
  {
    Header: 'Full Address',
    accessor: 'fullAddress',
    Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: 'Company Phone',
    accessor: 'companyPhone',
    Filter: ColumnFilter,
    // sticky: 'left'
  },

  {
    Header: 'Company Head Count',
    accessor: 'companyHeadCount',
    Filter: ColumnFilter,
  },
  {
    Header: 'Country',
    accessor: 'country',
    Filter: ColumnFilter,
  },
  {
    Header: 'City',
    accessor: 'city',
    Filter: ColumnFilter,
  },
  {
    Header: 'State',
    accessor: 'state',
    Filter: ColumnFilter,
  },
  {
    Header: 'Tag',
    accessor: 'tag',
    Filter: ColumnFilter,
    // sticky: 'left'
  },
  {
    Header: 'Full name',
    accessor: 'fullName',
    Filter: ColumnFilter,
  },
  // {
  //   Header: "Business Email",
  //   accessor: "businessEmail",
  //   sticky: "left",
  //   Filter: ColumnFilter,
  // },
  // {
  //   Header: "Personal Email",
  //   accessor: "personalEmail",
  //   Filter: ColumnFilter,
  //   // sticky: 'left'
  // },

  {
    Header: 'Lead ID',
    accessor: '_id',
    Filter: ColumnFilter,
  },
  {
    Header: 'Source Link',
    accessor: 'sourceLink',
    Filter: ColumnFilter,
  },
  {
    Header: 'Name Prefix',
    accessor: 'namePrefix',
    Filter: ColumnFilter,
  },

  {
    Header: 'Middle name',
    accessor: 'middleName',
    Filter: ColumnFilter,
  },

  {
    Header: 'Sur name',
    accessor: 'sureName',
    Filter: ColumnFilter,
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    Filter: ColumnFilter,
  },
  {
    Header: 'Personal Phone',
    accessor: 'personalPhone',
    Filter: ColumnFilter,
  },
  {
    Header: 'Employee Range',
    accessor: 'employeeRange',
    Filter: ColumnFilter,
  },
  {
    Header: 'Company Website',
    accessor: 'companyWebsite',
    Filter: ColumnFilter,
  },
  {
    Header: 'Company LinkedIn Link',
    accessor: 'companyLinkedinLink',
    Filter: ColumnFilter,
  },

  {
    Header: 'Company HQ Address',
    accessor: 'companyHQAddress',
    Filter: ColumnFilter,
  },
  {
    Header: 'Industry',
    accessor: 'industry',
    Filter: ColumnFilter,
  },
  {
    Header: 'Revenue',
    accessor: 'revenue',
    Filter: ColumnFilter,
  },
  {
    Header: 'Street',
    accessor: 'street',
    Filter: ColumnFilter,
  },
  {
    Header: 'Zip Code',
    accessor: 'zip',
    Filter: ColumnFilter,
  },
  {
    Header: 'Rating',
    accessor: 'rating',
    Filter: ColumnFilter,
  },
  {
    Header: 'Sheet ID',
    accessor: 'sheetID',
    Filter: ColumnFilter,
  },
  {
    Header: 'Sheet Name',
    accessor: 'sheetName',
    Filter: ColumnFilter,
  },
  {
    Header: 'Custom Field 1',
    accessor: 'customField1',
    Filter: ColumnFilter,
  },
  {
    Header: 'Custom Field 2',
    accessor: 'customField2',
    Filter: ColumnFilter,
  },
  // if need to active or delete lead
  //   {
  //     Header: "Active/Delete",
  //     accessor: "isActive",
  //     Filter: ColumnFilter,
  //   },
  {
    Header: 'Created Date',
    accessor: 'createdDate',
    Filter: ColumnFilter,
  },
];
