import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { useLoginContext } from "../context/login&Register_context";
import PageStructureOne from "../pages/DefaultPageStructure/PageStructure1";
import { tableStyle, themes } from "./Themes";

export const ThemeProviderToPages = ({ Reset, loading,filterLoading, allFilteredLeads,TableData, Columns, navData, title }) => {
  const { mode } = useLoginContext();

  const currentTheme = themes[mode];
  return (
    <ThemeProvider theme={currentTheme} tableStyle={currentTheme}>
      <PageStructureOne
        TableData={TableData}
        allFilteredLeads={allFilteredLeads}
        filterLoading={filterLoading}
        Reset={Reset}
        Columns={Columns}
        navData={navData}
        loading={loading}
        title={title}
      />
    </ThemeProvider>
  );
};
