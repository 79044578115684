import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { CSSTransition } from "react-transition-group";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useLeadServerContext } from "../context/lead_server_context";
import { LoadingFourSquare } from "./LoadingFourSquare";
import { useAdminstrationContext } from "../context/adminstration_context";

Modal.setAppElement("#root");

export const CreateUser = () => {
  const [isModalOpen, setModal] = useState(false);

  const {createUser} = useAdminstrationContext();

  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role,setRole] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const { uploadSheets, uploadSheet_loading } = useLeadServerContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    createUser(name, email, role , userId, password, passwordConfirm)

    setName('')
    setRole('')
    setEmail('')
    setUserId('')
    setPassword('')
    setPasswordConfirm('')
  }

  return (
    <Wrapper>
      <div className="btn_default" onClick={() => setModal(true)}>
        <span className="download_icon">
          <AiOutlineCloudUpload />
        </span>
        <span className='Create-text'>Create User</span>
      </div>
      <CSSTransition in={isModalOpen} timeout={300} classNames="my-node">
        <Modal
          isOpen={isModalOpen}
          style={{
            overlay: {
              zIndex: 2000,
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            },
            content: {
              height: "520px",
              width: "500px",
              left: "30%",
              backgroundColor: "white",
            },
          }}
        >
          <AiOutlineCloseCircle
            className="close_btn"
            style={{
              color: "black",
              fontSize: "1.5rem",
              float: "right",
              cursor: "pointer",
            }}
            onClick={() => setModal(false)}
          />
          <Wrapper>

          <form className='form' onSubmit={(e) => handleSubmit(e)}>
            <h3>Please fillup this form to create user</h3>
            <label className='form_label' htmlFor="text">Client ID: </label>
            <input required className='form_input' type="text" value={userId} onChange={(e) => setUserId(e.target.value)} />

            <label className='form_label' htmlFor="text">Name: </label>
            <input required className='form_input' type="text" value={name} onChange={(e) => setName(e.target.value)} />

            <label className='form_label' htmlFor="text">Email: </label>
            <input required className='form_input' type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

            {/* <label className='form_label' htmlFor="text">Role: </label>
            <input className='form_input' type="text" value={role} placeholder='client, user, pro-user, moderator' onChange={(e) => setRole(e.target.value)} /> */}

            <select required name="role" className='select_option' onChange={(e) => setRole(e.target.value)}>
              <option value="">--Please choose a role--</option>
              <option value="client">Client</option>
              <option value="user">User</option>
              <option value="pro-user">Pro-user</option>
              <option value="moderator">Moderator</option>
            </select>


            <label  className='form_label' htmlFor="text">Password: </label>
            <input required className='form_input' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />

            <label className='form_label' htmlFor="text">Password Confirm: </label>
            <input required className='form_input' type="password" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />

            <button type='submit'>Submit</button>
          </form>
          </Wrapper>
        </Modal>
      </CSSTransition>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  // form
  form {
    display: flex;
    flex-direction: column;
    padding-left: .5rem;

    h3 {
      padding: 0;
      margin: 0
    }
    input {
      padding: .3rem .5rem;
      font-size: 1.1rem;
      margin-bottom: .2rem;
    }
    label {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: .4rem;
      margin-top: .3rem;
    }
    button {
      font-size: 1.3rem;
      cursor: pointer;
      margin-top: .3rem;
      font-weight: 700;
      padding: .2rem .2rem;
    }

  } 

  .select_option {
    padding: .2rem .4rem;
      font-size: 1.2rem;
      margin-bottom: .1rem;
      margin-top: .4rem;
  }

  // others
  .fileUploadSection {
  }
  .upload-btn {
    margin-top: 5rem !important;
  }
  /* margin-left: 19%; */
  .btn_default {
    padding: 0rem 1rem 0.15rem 1rem;
  }
  .download_icon {
    font-size: 1.5rem !important;
    /* display: flex;
    flex-direction: row !important; */
  }

  .Create-text {
      width: 100%;
  }


  .ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
  }
  .my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
 
`;
