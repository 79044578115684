import React, { useEffect } from 'react';
import { ThemeProviderToPages } from '../components/ThemeProvider';
import navData from '../context/mockdata/nav-contents-admin';
import { tableStyle } from '../components/Themes';

import { useClientAreaContext } from '../context/client_area_context';
import { useLoginContext } from '../context/login&Register_context';
import { LeadServerCOLUMNS } from '../components/table/tableComponents/LeadServerColumns';
import { Link } from 'react-router-dom';

export const ClientArea = () => {
  const { user } = useLoginContext();
  let {
    filterClientLeads,
    getingClientData,
    clientLeads,
    clientLeadsLoading: loading,
    allFilteredLeads,
    filterClient_loading,
  } = useClientAreaContext();
  // console.info(user);

  useEffect(() => {
    if (user.name) {
      getingClientData(user);
    }
  }, [user]);

  const filteredUpto50Lead = [];

  // Sending only 49 filtered Lead to Leadserver UI
  filterClientLeads.forEach((element, i) => {
    if (i < 49) {
      filteredUpto50Lead.push(element);
    }
  });

  if (filteredUpto50Lead.length > 0) {
    clientLeads = filteredUpto50Lead;
  }

  if (user.role === 'client') {
    return (
      <>
        <ThemeProviderToPages
          TableData={clientLeads}
          allFilteredLeads={allFilteredLeads}
          Reset={getingClientData}
          Columns={LeadServerCOLUMNS}
          filterLoading={filterClient_loading}
          navData={navData}
          loading={loading}
          title={'Welcome To Client Area'}
          tableStyle={tableStyle}
        />
      </>
    );
  } else {
    return (
      <>
        <h1>
          Please Be A Client First! <Link to="/dashboard">Back to Home</Link>
        </h1>
      </>
    );
  }
};
