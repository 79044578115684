export const nav_logo =
  "https://www.khalidit.com/wp-content/uploads/2020/10/cropped-khalidit-logo-removebg-preview-180x180.png";

export const hostAddress = "https://leadserver.khalidit.com/api/v1";
// export const hostAddress = "http://127.0.0.1:8000/api/v1";

export const getToken = () => {
  const token = localStorage.getItem("jwt");
  if (!token) {
    return "";
  }
  return token;
};




export const checkingInputIsRight = (text) => {
  const regExp = /[a-zA-Z|0-9|^\S+$]/g;

  if(regExp.test(text)){
    return text
  } else {
    return 'Error';
  }
}

export const checkingFilterColumnDublicate = (oldFilterValue,newFilterValue) => {
  console.log(oldFilterValue,newFilterValue);
  let returnValue;

  
  const newColumn = newFilterValue.slice(1).split('=');
  //  console.log(newColumn);
  const regexWithMiddleReplace = new RegExp(`${newColumn[0]}\\s*=\\s*([\\S\\s].+?(?=&))`);
  const regexWithLastReplace = new RegExp(`${newColumn[0]}\\s*=\\s*([\\S\\s]*)`);
 
  // regex checking
  // const re2 = /titlePosition\s*=\s*([\S\s]+)*&/;
  // const re2 = /titlePosition\s*=\s*([\S\s]+)[a-zA-Z]*&/;
  // const re2v2 = /titlePosition\s*=\s*([\S\s]+)/;
  // const re2v3 = /titlePosition\s*=\s*([\S\s].+?(?=&))/;
  // const re3 = /titlePosition=.+$/

  
  if(oldFilterValue.includes(newColumn[0])) {
    const splitwithAnd = oldFilterValue.split('&');
    const lastColumn = splitwithAnd[splitwithAnd.length - 1].split('=')[0]
    const lastColumnValue = splitwithAnd[splitwithAnd.length - 1].split('=')[1]
    console.log(lastColumnValue);

        let ourWantingValue;
        if(oldFilterValue.includes(`&${newColumn[0]}`)) {
            if(lastColumn === newColumn[0]) {

            // work for last string replaceing start
            ourWantingValue = oldFilterValue.replace(regexWithLastReplace, newFilterValue);
            console.log(ourWantingValue);


            returnValue = ourWantingValue;
            // work for last string replaceing end
            } else {
            //work for middle string replacing start
          ourWantingValue = oldFilterValue.replace(regexWithMiddleReplace, newFilterValue);
          
            console.log(ourWantingValue);
            returnValue = ourWantingValue;
            //work for middle string replacing end
            }

        }else if(oldFilterValue.includes(`?${newColumn[0]}`)) {

          //work for Fist string replacing start

            // if filterValue have other filter column or not
            if(oldFilterValue.includes('&')) {
              ourWantingValue = oldFilterValue.replace(regexWithMiddleReplace, newFilterValue.replace('&', ''))
            }else {
              ourWantingValue = oldFilterValue.replace(regexWithLastReplace, newFilterValue.replace('&', ''))
            }
            
            returnValue = ourWantingValue;
          //work for Fist string replacing end

        } else {
          console.log('regex without &');
        }

  } else {
    returnValue = `${oldFilterValue}${newFilterValue}`

  }

 return returnValue;
}
