import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams, Redirect, } from 'react-router-dom';
import { hostAddress } from '../utils/helpers';

function ResetPassword() {
  const { id, token } = useParams();

  const [serverErrorMessage, setServerErrorMessage] = useState();
  const [postError, setPostError] = useState();
  const [formData, setFormData] = useState({
    password: '',
    conformPassword: '',
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    passToken();
  }, []);

  const passToken = () => {
    const config = {
      id,
      token,
    };
    axios
      .get(`${hostAddress}/forget/reset-password`, { params: config })
      .then(res => setServerErrorMessage(res.data.msg))
      .catch(error => console.log(error));
  };

  const handelSubmit = e => {
    e.preventDefault();
    const isValidPassword = formData.password === formData.conformPassword;
    if (formData.password === '' && formData.conformPassword === '') {
      alert('please enter a  password');
      return;
    }
    if (!isValidPassword) {
      alert('conform password is not valid');
      return;
    }
    axios
      .post(`${hostAddress}/forget/reset-password`, { formData, id })
      .then(res => {
        setPostError(res.data.msg);
        Redirect('/login')
      })
      .catch(error => console.log(error));
    setFormData({
      password: '',
      conformPassword: '',
    });
  };

  return (
    <>
      {serverErrorMessage ? (
        <>
          <h1 style={{ textAlign: 'center' }}>{serverErrorMessage}</h1>
          <Link style={{ color: 'red' }} to="/">
            Go Home
          </Link>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <form style={{ display: 'block' }} onSubmit={handelSubmit}>
            <div style={{ padding: '5px 0px' }}>
              <div>{postError}</div>
              <input
                value={formData.password}
                type={show ? 'text' : 'password'}
                placeholder="Enter Your password"
                onChange={e =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </div>
            <div style={{ padding: '5px 0px' }}>
              <input
                value={formData.conformPassword}
                type={show ? 'text' : 'password'}
                placeholder="Enter Your confrom password"
                onChange={e =>
                  setFormData({ ...formData, conformPassword: e.target.value })
                }
              />
            </div>
            <div>
              <input type="checkbox" onClick={e => setShow(e.target.checked)} />
              <spa>{show ? 'hide password' : 'show password'}</spa>
            </div>
            <div style={{ padding: '5px 0px' }}>
              <button type="submit">Reset Password</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ResetPassword;
