import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import styled from "styled-components";
import { Link } from "react-router-dom";
import PageStructureOne from './DefaultPageStructure/PageStructure1';
import navData from '../context/mockdata/nav-contents-admin';
import { ThemeProviderToPages } from '../components/ThemeProvider';
import {AdminstrationColumns} from '../components/table/tableComponents/AdminstrationColumns'
import { useLeadServerContext } from '../context/lead_server_context';
import mockUserData from '../context/mockdata/userMockData.json'
import {LeadServerCOLUMNS} from '../components/table/tableComponents/LeadServerColumns'
import { useAdminstrationContext } from '../context/adminstration_context';

function AdministrationUser() {
  let {filterUsers,filterUsers_loading, getAllUser, users: usersData, allUsers, usersLoading: loading,} = useAdminstrationContext();

    useEffect(() => {
      getAllUser();
    }, []);

    

  const filteredUpto50 = [];

  // Sending only 49 filtered Lead to Leadserver UI
  filterUsers.forEach((element, i) => {
    if (i < 49) {
      filteredUpto50.push(element);
    }
  });

  if (filteredUpto50.length > 0) {
    usersData = filteredUpto50;
  }

    return (
        <>
        <ThemeProviderToPages TableData={usersData} allFilteredLeads={allUsers} Columns={AdminstrationColumns} Reset={getAllUser} navData={navData} filterLoading={filterUsers_loading} loading={loading} title={'Adminstration'}/>
        </>
    )
}


export default withRouter(AdministrationUser)
