import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useLoginContext } from "../context/login&Register_context";

export const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useLoginContext();

  let authCheckfromLocalStorage = localStorage.getItem('isAuthenticated')

    authCheckfromLocalStorage = JSON.parse(authCheckfromLocalStorage)


  return (
    <Route
      {...rest}
      render={() => {
        return authCheckfromLocalStorage || isAuthenticated ? children : <Redirect to="/login"></Redirect>;
      }}
    ></Route>
  );
};
