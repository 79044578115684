import React from "react";
import styled from "styled-components";
import { useLeadServerContext } from "../context/lead_server_context";

export const ShowFilteringQuery = () => {
  const { filterQuery } = useLeadServerContext();

  return (
    <Wrapper>
      {/* <p>Your Filtering Text:{filterQuery ? ` ${filterQuery[1].toUpperCase() + filterQuery.slice(2).replace('?', '')}` : ' Please filter something :)'}</p> */}
      {/* <p>Your Filtering Text: We are working on it</p> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-left: 6%;
  p {
    position: absolute;
    margin: 0;

    font-size: 1.15rem;
    margin-top: -1.75rem;
    padding: 0;
  }
`;
