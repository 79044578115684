import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  useTable,
  useBlockLayout,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from 'react-table';
import { useLocation } from 'react-router-dom';
import { useSticky } from 'react-table-sticky';
import { AdminstrationColumns } from './AdminstrationColumns';
import { LeadServerCOLUMNS } from './LeadServerColumns';
import { EmployeeCOLUMNS } from './EmployeeColumn';
import { FaSort } from 'react-icons/fa';
import { RiFilterOffLine } from 'react-icons/ri';
import { GlobalFilter } from './GlobalFilter';
import { Loading } from '../../Loading';
import { useLeadServerContext } from '../../../context/lead_server_context';
import { Pagination } from '../../Pagination';
import { ShowFilteringQuery } from '../../ShowFilteringQuery';
import useMockData from '../../../context/mockdata/userMockData.json';

import ColumnFilter from './ColumnFilter';
import { useLoginContext } from '../../../context/login&Register_context';
import { Link } from 'react-router-dom';

// I declare it for sent data after input field submit for filter.
let queryStringCarry;
const TableData = ({ Reset, actualData, allFilteredLeads, Columns }) => {
  // get user

  let { specific } = useLeadServerContext();
  const { user } = useLoginContext();
  const [userId, setUserId] = useState();
  const [users, setUser] = useState('');
  const [name, setName] = useState('');
  const [hoverContent, setHoverContent] = useState('');
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns: Columns,
      data: actualData,
      defaultColumn,
    },
    useBlockLayout,
    useSticky,
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setAllFilters,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter } = state;

  // QueryString making start------------
  let filterValueString = [];
  const allInputValue = [];
  const wantedInputValue = [];
  const allInputFilterName = [];
  let allWantedString = [];

  // i am using useEffect here, because of solving the column.filterValue showing undefined problem and replace undefined to null.
  useEffect(() => {
    const mainValueforfilter = filterValueString.map(e => {
      return e;
    });
    for (let index = 0; index < mainValueforfilter.length; index++) {
      let element = mainValueforfilter[index];
      if (element === undefined) {
        element = 'null';
        allInputValue.push(element);
      } else {
        allInputValue.push(element);
      }
    }
    // creating wanted input value
    allInputValue.forEach(e => {
      if (e !== 'null') {
        wantedInputValue.push(e);
      }
    });

    //

    // creating filtering string
    function creatingString(array1, array2) {
      for (let index = 0; index < array1.length && array2.length; index++) {
        if (index === 0) {
          allWantedString.push(`?${array2[index]}=${array1[index]}`);
        } else {
          allWantedString.push(`&${array2[index]}=${array1[index]}`);
        }
      }
    }

    creatingString(wantedInputValue, allInputFilterName);

    // main filter query string from array
    let queryString;
    if (allWantedString) {
      queryString = allWantedString.reduce((a, b) => a + b, '');
    }

    queryStringCarry = queryString;
  }, [filterValueString]);

  // QueryString making end----------

  // let textContentmff;
  // geting data for each table cell
  const showingTextContent = e => {
    // textContentmff = e.target.textContent;
  };

  // for inline styling each th and td
  // const customInlineCss = {
  //   width: '180px',
  //   display: 'inline-block',
  //   boxSizing: 'border-box',
  //   position: 'sticky',
  //   zIndex: 3,
  //   left: '0px',
  //   cursor: 'pointer'
  // }

  const location = useLocation();

  //  Loading data after click reset
  const handleResetTable = () => {
    // checking if the table for client then we need to send user into the function
    if (location.pathname.includes('client-area')) {
      Reset(user);
    } else {
      Reset(user);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setHoverContent('');
    }, 3000);
  }, [hoverContent]);
  const deleteHandeler = () => {};
  return (
    <Wrapper>
      <div className={user.role === 'user' ? 'table_container' : ''}>
        <div className="table-top-bar">
          <span className="filter_Reset_btn" onClick={() => handleResetTable()}>
            <button className="clear_filter" onClick={() => setAllFilters([])}>
              Reset <RiFilterOffLine />
            </button>
          </span>
          <span className="global_filter_btn">
            {/* for filtering globally on table, now it comment out */}
            {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
            <GlobalFilter />
          </span>
          <ShowFilteringQuery />
          <div className="all-data-area">
            {location.pathname === '/adminstration' ? (
              <p>Total users: {allFilteredLeads}</p>
            ) : (
              <p>Total leads: {allFilteredLeads}</p>
            )}
          </div>
        </div>

        <div {...getTableProps()} className="table sticky table-area">
          <div className="header">
            {headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps()}
                    className="th th-custom-style"
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="th th-custom-style"
                    >
                      {column.render('Header')}
                      <span>
                        <FaSort className="sort-icon" />
                      </span>

                      <p className="hidden">
                        {column.filterValue !== undefined
                          ? allInputFilterName.push(column.id)
                          : ''}

                        {filterValueString.push(column.filterValue)}
                      </p>
                    </div>
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            {rows.map(row => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell, ind) =>
                    location.pathname.includes('lead-server/sheets') ||
                    location.pathname.includes('adminstration') ||
                    location.pathname.includes('client-area/sheets') ? (
                      <Link
                        key={ind}
                        ///seecific-lead-server/${userId}
                        to={`/seecific-lead-server/${userId}/${users}`}
                        {...cell.getCellProps()}
                        className="td td-custom-style link mouse-over-parent "
                        onMouseOver={e => {
                          setUserId(
                            cell.row.values.clientId == e.target.innerText ||
                              cell.row.values.sheetName == e.target.innerText ||
                              (e.target.innerText && cell.row.values.userId)
                              ? cell.row.values.userId ||
                                  cell.row.values.clientId +
                                    '-' +
                                    cell.row.values.sheetName
                              : null
                          );
                          setUser(
                            cell.row.values.clientId == e.target.innerText ||
                              (e.target.innerText && cell.row.values.userId)
                              ? 'userId'
                              : 'sheetname'
                          );
                          setHoverContent(e.target.innerText);
                        }}
                      >
                        {cell.render('Cell')}
                      </Link>
                    ) : (
                      <div
                        {...cell.getCellProps()}
                        className="td td-custom-style"
                        onMouseOver={e => {
                          setHoverContent(e.target.innerText);
                          showingTextContent();
                        }}
                      >
                        {cell.render('Cell')}
                      </div>
                    )
                  )}
                  <p
                    className="mouse-over"
                    style={{
                      display: hoverContent ? 'inline' : 'none',
                    }}
                  >
                    {hoverContent}
                  </p>
                  <button
                    className="action-button"
                    onClick={() => deleteHandeler()}
                  >
                    DELETE
                  </button>
                </div>
              );
            })}
          </div>
          {/* <div className="textContentWindow">
               {isShown ? <p className='textContentWindow-content'>{cellTextContent}kdfjkdjfkdfjsdfkjsdkfjsadkjfkd</p> : ''}
             </div>
             
             
             ? (
                      <Link
                        to={`/heyper/${hyperLink}`}
                        {...cell.getCellProps()}
                        className="td td-custom-style"
                        // onMouseOver={showingTextContent}
                        onMouseOver={e =>
                          setHyperLink(
                            e.target.innerText && cell.row.values._id
                              ? cell.row.values._id
                              : ''
                          )
                        }
                      >
                        {cell.render('Cell')}
                      </Link>
                    ) : (
                      <div
                        {...cell.getCellProps()}
                        className="td td-custom-style"
                        onMouseOver={showingTextContent}
                      >
                        {cell.render('Cell')}
                      </div>
                    )
             
             */}
        </div>
        <Pagination allFilteredLeads={allFilteredLeads} />
      </div>
    </Wrapper>
  );
};

export default TableData;
// this function will call from columFilter Form onSubmit.
export function SendingQueryString(value) {
  return queryStringCarry;
}

const Wrapper = styled.section`
  .table_container {
    margin-top: 2rem;
  }
  /* display: block;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    font-size: .9rem;
    word-wrap: break-word;
    
    
  }
 tfoot td {
    background-color: #fff;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;

  }
  
  td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    max-width: 5rem;
    max-height: 4rem;
  }
  th {
    background-color: #e6e6e6;
    border: 1px solid #dddddd;
    text-align: left;
    color: #141414;
    font-weight: 700;

    padding: 5px;
  }
  
  tr:nth-child(even) {
    background-color: #ebebeb;

  } */
  .clear_filter {
    background-color: var(--clr-grey-9);
    display: inline-block;
    font-size: 1rem;
    /* border: none; */
    border-bottom: 0.1px solid black;
    color: #303030;
    font-weight: 400;
    border-radius: 7px 7px 0 0;
    cursor: pointer;
    transition: all 0.2s;
  }
  .clear_filter:hover {
    background-color: var(--clr-grey-7);
    color: #242424;
  }

  // table top bar

  .table-top-bar {
    /* display: inline-block;
    width: 20rem; */
  }
  .filter_Reset_btn {
    margin-right: 0.1%;
  }
  .global_filter_btn {
    display: inline-block;
  }
  .all-data-area {
    position: relative;
    margin-left: 75.3rem;

    p {
      bottom: 20%;
      margin: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #242424;
      background-color: #3ebadb2e;
      padding: 0.3rem 1rem;
      border-radius: 23px 1px 1px 1px;
      width: 4.5%;
      position: absolute;
    }
  }

  // Start design for sticky table
  .table-area {
    height: 71vh !important;
    width: ${props => props.theme.width};
  }
  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 0 0 0 8px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }

    .th-custom-style {
      text-align: center;
      font-size: 0.95rem;
      font-weight: 600;
      color: #484848;
      padding-top: 7px;
    }

    .td-custom-style {
      overflow: hidden;
      padding: 8px;
      text-overflow: clip;
      white-space: nowrap;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }
      .link {
        text-decoration: none;
        color: #000;
      }
      .link:hover {
        color: #07697f;
        text-decoration: underline;
      }
      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
  .sort-icon {
    color: #07697f;
  }
  // Responsive Table for top resulation
  @media (min-width: 1366px) {
    .table-area {
      //our own pc resulation
    }
  }
  @media (min-width: 1400px) {
    .table-area {
      height: 80vh !important;
      width: 25% !important;
      margin-left: 0.2%;
    }
    .table-top-bar {
      margin-left: 0.2%;
    }
  }
  @media (min-width: 1536px) {
    .table-area {
      height: 80vh !important;
      width: 26% !important;
      margin-left: 0.5%;
    }
    .table-top-bar {
      margin-left: 0.5%;
    }
  }
  @media (min-width: 1920px) {
    .table-area {
      height: 82.5vh !important;
      width: 25% !important;
      margin-left: 1%;
    }
    .table-top-bar {
      margin-left: 1%;
    }
  }

  /* // TextContentModal window style
 .textContentWindow {
   position: relative;
 }
 .textContentWindow-content {
  position: absolute;
  
 } */

  // Classed for hide
  .hidden {
    display: none;
  }
`;
