import axios from "axios";
import { getToken, hostAddress } from "../../utils/helpers";

export const getFilterDataResponce = async (filterQuery,model) => {


  let responce;
  let filteredData;
  let allFilteredData

  // If user filter by '' Start
  if(filterQuery === '') {
      alert('Please provide meaningful filter text')
      filteredData = ''
      allFilteredData = ''
      return {filteredData, allFilteredData}

    } else if (filterQuery.includes('globalfilter')) {
      return alert('Please Reset table.')

    } else {
      // Geting filter data from backend
      try {
        const token = getToken();
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

       
        //check if filtering sheets for client or not, if admin and anything else do filtering with out client id.
        if(model === 'sheets/client') {
          // make filter value for geting client sheet;
          const newFilterValue = filterQuery.replace('?', '&')
          //check if filtervalue containing client Id
          if(newFilterValue.includes('clientId')) {
            responce = await axios.get(
            `${hostAddress}/${model}/filter/${filterQuery}`,
            config
          );
          } else {
            responce = await axios.get(
              `${hostAddress}/${model}/filter/?clientId=${'5522'}${newFilterValue}`,
              config
            );
          }
          filteredData = responce.data.data.data;
          allFilteredData = responce.data.filteredDataLength;
        } else {
          responce = await axios.get(
            `${hostAddress}/${model}/filter/${filterQuery}`,
            config
          );

          filteredData = responce.data.data.data;
          allFilteredData = responce.data.filteredDataLength;
        }
        
        
      
  
        
        return {filteredData, allFilteredData}
      } catch (error) {
        const errorMessage = JSON.parse(error.request.response);
        alert(errorMessage.message);
      }
    }
}

export const getDataViaPageResponce = async (model, page, filterQuery, limit, user) => {
 
  try {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    let responce;

    // for client area
    if(user) {
      responce = await axios.get(
        `${hostAddress}/leads/filter/?clientId=${user.userId}&page=${page}&limit=${limit || 50}`,
        config
        );
        const filteredData = responce.data.data.data;
        const allFilteredData = responce.data.filteredDataLength;
    
          return {filteredData, allFilteredData}
    }

    // if filter query have globalfilter tag
    if(filterQuery.includes('globalfilter')) {
    responce = await axios.get(
      `${hostAddress}/${model}/globalfilter/?page=${page}&limit=${limit || 50}&filterQuery=${filterQuery.replace('globalfilter?', '')}`,
      config
    );

    const filteredData = responce.data.data.data;
    const allFilteredData = responce.data.filteredDataLength;

    //Remake filter query for show to Your show filter
    // const filterQuery = `globalfilter?${filterQuery}`
      return {filteredData, allFilteredData}
    }

    if(filterQuery && !filterQuery.includes('globalfilter')) {
      let responce;
      if(limit > 50 && limit !== '') {
        // filter with limit 
        responce = await axios.get(`${hostAddress}/${model}/filter/${filterQuery}&page=${page}&limit=${limit}`, config)
      }else {
        // filter without limit
        responce = await axios.get(`${hostAddress}/${model}/filter/${filterQuery}&page=${page}`, config)
      }
      const filteredData = responce.data.data.data;
      const allFilteredData = responce.data.filteredDataLength;

      return {filteredData, allFilteredData}
     

    } else {
      if(!filterQuery.includes('globalfilter')) {
        const responce = await axios.get(`${hostAddress}/${model}?page=${page}`, config);
        const filteredData = responce.data.data.docs;
        const allFilteredData = responce.data.allDocs;

      return {filteredData, allFilteredData}

      }
    }
  } catch (error) {
    const errorMessage = JSON.parse(error.request.response);
    alert(errorMessage.message);
  }

}

export const globalFilterResponce = async (model, filterQueryGlobal) => {
  try {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  
    const responce = await axios.get(
      `${hostAddress}/${model}/globalfilter/?page=1&filterQuery=${filterQueryGlobal}`,
      config
    );
  
    const filteredData = responce.data.data.data;
    const allFilteredData = responce.data.filteredDataLength;
  
    
      return {filteredData, allFilteredData};
  } catch (error) {
    const errorMessage = JSON.parse(error.request.response);
    alert(errorMessage.message);
  }
}